export default {
  code: "es",
  title: "Español",
  flag: "🇪🇸",
  meta: {
    index: {
      title: 'Los mejores trabajos académicos,\n' +
        '10 veces más rápido con IA',
      description: 'Sin Hesse: Miras una página en blanco durante horas\n' +
        'Con Hesse: Generas excelentes textos apretando simplemente un botón'
    }
  },
  validations: {
    required: "{_field_} es un campo obligatorio",
    min: "{_field_} debe tener al menos {length} caracteres",
    max: "{_field_} no debe ser más largo que {length} caracteres",
  },
  buttons: {
    close: "Cerrar",
    upgrade: "Actualizar",
    get_more_words: "Obtener más palabras ahora",
    login: "Iniciar sesión",
    logout: "Cerrar sesión",
    cancel: "Cancelar",
    yes: "Sí",
    no: "No",
    tryout: "Probar",
    add: "Agregar",
    report: "Reportar",
    save: "Guardar",
    edit: "Editar",
    copy: "Copiar",
    delete: "Borrar",
    generate: "Generar",
    signup: "Registrarse",
    continue: "Continuar",
    submit: "Enviar",
    add_source: "Agregar Fuente",
    select_source: "Seleccionar Fuente",
    new_document: "Nuevo Documento",
    back: "Atrás",
    local_document: {
      title: "Un documento de mi computadora",
      type: "Subir Documento"
    },
    web_document: {
      title: "¿Tienes un enlace a un documento?",
      type: "Enlace Web/Documento"
    },
  },
  labels: {
    missing_subscription: "Necesitas una suscripción para usar esta herramienta.",
    booked: "Reservado",
    account: "Cuenta",
    subscription: "Suscripción",
    name: "Nombre",
    email: "Correo electrónico",
    limit: "Límite",
    settings: "Configuración",
    words: "Palabras",
    usage: "Uso",
    extra_words: "Incluidas {words} palabras adicionales",
    show_all_applications: "Mostrar todas las aplicaciones",
    show_all: "Mostrar todas",
    start_page: "Página de inicio",
    is_favorite: "En favoritos",
    add_favorite: "Agregar a favoritos",
    wordlimit_reached: "Límite de palabras alcanzado",
    wordlimit_increase: "Aumentar límite de palabras",
    contact: "Contactar",
    history: "Historial",
    show_history: "Mostrar historial",
    inprint: "Información corporativa",
    dataregulation: "Política de datos",
    tos: "Términos y condiciones",
    month: "Mes",
    cancel_anytime: "Cancelar en cualquier momento",
    try_now: "Prueba gratuita",
    book_again: "Suscribirse de nuevo",
    book: "Suscribirse ahora",
    upgrade_to: "Cambiar a {product}",
    copied_link: "Enlace copiado",
    subject: "Asunto",
    generated_by_hesse: "Generado por Hesse en 3 segundos",
    use_template: "Usar plantilla",
    show_mode: "Mostrar más",
    optional: "Opcional",
    my_documents: "Mis Documentos",
    my_sources: "Mis Fuentes",
    my_projects: "Mis Proyectos",
    add_source: "Agregar Fuente",
    select_source: "Seleccionar Fuente",
    page: "Página {page}",
    pages: "Páginas",
    unknown_author: "Autor desconocido",
    checker_results: "Resultados del detector de IA",
    human: "humano",
    citation_style: "Estilo de cita",
    started_at: "comenzado el",
    ends_at: "termina el",
    cancel_subscription: "cancelar suscripción",
    file_too_large: "El archivo es demasiado grande (máx. 50 MB)",
    relevance: "Relevancia",
    relevance_help: "La relevancia mide qué tan bien la fuente se ajusta al tema buscado en términos de contenido.",
    quality: "Calidad",
    quality_help: "La calidad mide qué tan buena es la fuente tanto en contenido como en forma.",
    trustworthiness: "Confiabilidad",
    trustworthiness_help: "La confiabilidad mide, principalmente en base a los metadatos de la fuente, qué tan fiable es.",
    high: "alto",
    normal: "normal",
    low: "bajo",
    default: "predeterminado",
  },
  blog: {
    written_by: "Escrito por",
    read_article: "Leer artículo",
  },
  source: {
    select: {
      empty: {
        headline: "Aquí no hay nada aún.",
        hint: "Puedes marcar fuentes en 'Encontrar Fuentes' y usarlas en varias herramientas."
      },
    },
    table: {
      name: "Nombre",
      type: "Tipo",
      created_at: "Creado el",
    },
    type: {
      document: "Documento",
      article: "Artículo",
      paper: "Papel",
    },
    hint: {
      not_usable: "Las fuentes que aún no se han procesado no se tendrán en cuenta al escribir.",
    },
    processing: "se está procesando...",
    recent: "utilizado recientemente",
    bookmark: "marcar",
    bookmarked: "marcado"
  },
  placeholders: {
    url: "https://en.wikipedia.org/wiki/Radiocarbon_dating"
  },
  groups: {
    research: "Investigación",
    essay: "Ensayo",
  },
  flows: {
    summarize: {
      headline: "Resumir",
      description: "¿Qué quieres resumir?"
    },
    titles: {
      summarize_document: "Documento",
      summarize_text: "Texto",
      summarize_article: "Artículo",
      summarize_source: "Fuente",
    },
    prefill: {
      summarize_document: "Resumir el documento",
    }
  },
  messages: {
    get_more_words: "Obtener más palabras ahora",
    contact_success: "¡Gracias por tu mensaje!",
    error: "Algo no funcionó. Por favor, inténtalo de nuevo más tarde",
  },
  validation: {
    required: "{_field_} es un campo obligatorio",
    min: "{_field_} debe tener al menos {length} caracteres",
    max: "{_field_} no puede tener más de {length} caracteres",
    maxarray: "{_field_} tiene demasiados caracteres",
  },
  domains: {
    try: {
      headline: "Hesse Pro",
      loading: "Estamos activando tu cuenta de prueba...",
    },
    openai: {
      status: {
        unreliable: 'Actualmente tenemos una carga de servidor aumentada. Puede haber interrupciones. Si algo no funciona, por favor, inténtalo de nuevo más tarde.',
        working_on_fix: 'Actualmente puede haber cortes. Estamos trabajando en una solución. Vuelva a intentarlo más tarde.',
      }
    },
    ai_checker: {
      hint: 'Los detectores de IA son herramientas que verifican el contenido de la IA de los textos. Haz clic en una de las tarjetas para ir a la herramienta correspondiente.',
    },
    cookie: {
      headline: "Cookies 🍪",
      text1:
        'Al hacer clic en "Aceptar todas las cookies", usted acepta el almacenamiento de cookies en su dispositivo para mejorar la navegación del sitio, analizar el uso del sitio y asistir en nuestros esfuerzos de marketing.',
      analytics: 'Cookies de rendimiento',
      analytics_text:
        'Estas cookies nos permiten analizar el comportamiento del usuario en Hesse.ai de manera anónima para optimizar la experiencia del usuario.',
      marketing_text:
        'Estas cookies pueden ser establecidas por nuestros socios publicitarios a través de nuestro sitio web. Pueden ser utilizadas por estas compañías para crear un perfil de sus intereses y mostrarle anuncios relevantes en otros sitios.',
      marketing: 'Cookies de marketing',
      technical: 'Cookies funcionales',
      technical_text:
        'Estas cookies son necesarias para garantizar un uso seguro y confiable.',
      consent: 'Aceptar todas las cookies',
      save: 'Guardar preferencias',
      decline: 'Rechazar',
    },
    write: {
      confirm_delete_paragraph: "¿Eliminar el párrafo?",
      save_changes: "Guardar cambios",
      keep_writing: "Seguir escribiendo",
    },
    feedback: {
      title: 'Danos comentarios adicionales',
      description: 'Estamos constantemente tratando de mejorar nuestros resultados. Por favor, describe qué te gustaría cambiar.',
    },
    report: {
      title: "¿Informar realmente sobre esta respuesta?",
      reason: "Motivo del informe",
      description: "Informa sobre los resultados que contengan contenido crítico o ilegal.",
      result_good: "El resultado es útil",
      result_bad: "El resultado no es útil",
    },
    pricing: {
      monthly_wordlimit: "{limit} palabras por mes",
      increase_limit: "Aumentar límite",
      wordlimit_reached: "Has alcanzado tu límite de palabras",
      wordlimit_reached_description: "La cuota de este mes se ha agotado. Si deseas continuar, debes aumentar tu límite.",
      wordlimit_nearly_reached: "Tus palabras están a punto de agotarse",
      get_more_words: "Obtener más palabras",
      get_more_words_description: "Los buenos trabajos requieren muchas palabras, lo cual no siempre es barato.<br /> Sin embargo, hemos hecho nuestros paquetes lo más económicos posible.",
      error_title: "No se pudo realizar la reserva",
      error_description: "Contáctanos: te ayudaremos de inmediato",
      text1: "¿Cuántas son 10.000 palabras?",
      text2: "Esto fueron 10.000 palabras",
      referral_title: "¿Todavía no puedes decidirte?",
      referral_title2: "¿Necesitas más palabras?",
      referral_description: "Comparte tu enlace con amigos. Obtén 1.000 palabras gratis por cada nuevo registro a través de tu enlace.",
    },
    survey: {
      title: "¿Cómo calificarías a Hesse.ai?",
      suggestions: "¿Qué cambiarías?",
      success_title: "¡Muchas gracias por tus comentarios!",
      success_text: "Nos ayudas a mejorar continuamente Hesse.ai",
      abort: "No evaluar",
    },
    cards_hint: {
      title: "Las tarjetas están disponibles ahora",
      subtitle: "¿Crear automáticamente <strong>tarjetas</strong> de <strong>lectura</strong>?",
      cta: "Crear tarjetas ahora",
    },
    share_modal: {
      headline: "Comparte Hesse – y obtén palabras gratis",
    },
    login_page: {
      loading: "Iniciando sesión...",
      error: "El inicio de sesión no funcionó",
      delayed: "El inicio de sesión está tardando más de lo habitual.<br /> Por favor, verifica tu conexión a internet y vuelve a intentarlo si es necesario.",
      try_again: "Iniciar sesión nuevamente",
    },
    sidebar: {
      create_own_application: "Crear aplicación propia",
      hesse_cards: "Hesse Cards 🃏",
      all_applications: "Todas las aplicaciones",
      favourites: "Mis favoritos",
    },
    profile: {
      title: "Configuración de perfil",
      limit: "Límite",
      used_tokens: "Tokens utilizados",
      get_more_words: "Obtener más palabras ahora",
      no_subscription: "Aún no tienes una suscripción.",
      upgrade_now: "Actualizar ahora",
      manage_subscription: "Administrar suscripción",
      delete_account: "Borrar cuenta",
      deleted: "Tu cuenta ha sido eliminada. Si inicias sesión, se creará una nueva cuenta.",
    },
    contact: {
      title: "Contacto",
      subtitle: "Contáctanos",
    },
    error_page: {
      title404: "404 - Página no encontrada",
      title: "Se ha producido un error",
      text: "Ayúdanos a mejorar Hesse.ai.<br /> Describe brevemente cómo hacías antes de encontrar esta página.",
      cta: "Ir a la página de inicio",
    },
    success_page: {
      loading: "Cargando...",
      booking_success: "¡Tu reserva se realizó con éxito!",
      booked_product: "Has reservado {productName}",
      start_now: "Comenzar ahora",
    }
  },
  typewriter: {
    writeEssay: [
      // 'Buscando en la base de datos de fuentes...',
      // 'Buscando fuentes con Google...',
      'Generando textos...',
      'Cifrando textos...',
      'Comprobación de plagio...',
      'Herramientas de verificación de IA en ejecución...',
      'Textos ya no rastreables ✔'
    ],
    writeOutline: [
      'Generando textos...',
      'Cifrando textos...',
      'Comprobación de plagio...',
      'Herramientas de verificación de IA en ejecución...',
      'Textos ya no rastreables ✔'
    ],
    writeIntroduction: [
      'Generando textos...',
      'Cifrando textos...',
      'Comprobación de plagio...',
      'Herramientas de verificación de IA en ejecución...',
      'Textos ya no rastreables ✔'
    ],
  },
  project: {
    labels: {
      incomplete: "Incompleto",
    },
    create: {
      label: "Crear nuevo proyecto",
      buttons: {
        finish: "Terminar",
        next: "Siguiente",
      },
      welcome: {
        title: "Bienvenido a Proyectos",
        description: "Los proyectos son una forma de organizar tu investigación y escritura. Al crear un proyecto, puedes mantener el contexto de tu escritura consistente y crear textos más rápido.",
        cta: "¿Listo para comenzar?",
        button: "Crear primer proyecto",
      },
      error: {
        title: "Error",
        leave: "Salir de la creación del proyecto",
        retry: "Intentar de nuevo",
      },
      steps: {
        topic: {
          enter: {
            title: "Tu Tema",
            description: "Por favor, escribe tu tema o idea. Puede ser solo unas pocas palabras o una frase corta que capture la esencia de lo que quieres escribir.",
            label: "Escribe tu tema aquí",
          },
          assess: {
            title: "Tu Tema",
            description: "Evaluación de tu tema",
            feedback: "Comentarios: ",
            label: "Tema",
          }
        },
        language: {
          title: "Idioma",
          description: "Creemos que quieres escribir en {language}. ¿Es correcto o prefieres cambiarlo?",
          label: "Idioma",
        },
        length: {
          title: "Requisitos de Investigación",
          description: "¿Cuántas páginas debe tener tu investigación?",
          labels: {
            min: "Mínimo",
            max: "Máximo",
          }
        },
        approach: {
          title: "Enfoque de Investigación",
          description: "¿Cómo te gustaría abordar tu investigación?",
          label: "Estilo"
        }
      }
    },
    delete: {
      title: "Eliminar proyecto",
      description: "¿Estás seguro de que deseas eliminar este proyecto?",
    }
  }
};
